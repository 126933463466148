<template>
	<div
			v-if="columns.length"
			:class="$style.custom_table"
	>
		<div :class="$style.custom_table__columns">
			<div
					v-for="({column, width, fontSize, sort}, i) in columns"
					:key="i"
					:class="$style.custom_table__columns_column"
					:style="{
						width: width ? width + '%' : 5 + 'rem',
						fontSize: fontSize ? fontSize + 'rem' : 1 + 'rem'
					}"
			>
				{{ column }}
				<div
						v-if="sort"
						:class="$style.custom_table__columns_column_sort"
				>
					<svg
							width="9"
							height="7"
							viewBox="0 0 9 7"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							@click="sortItems({
								key: sort,
								method: 'asc',
								index: i
							})">
						<path
								d="M1.50281 1L4.7191 6L7.93539 1"
								:stroke="sortConfig.key === sort && sortConfig.method === 'asc' ? 'red' : 'black'"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
						/>
					</svg>
					<svg
							width="9"
							height="7"
							viewBox="0 0 9 7"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							@click="sortItems({
								key: sort,
								method: 'desc',
								index: i
							})">
						<path
								d="M1.50281 1L4.7191 6L7.93539 1"
								:stroke="sortConfig.key === sort && sortConfig.method === 'desc' ? 'red' : 'black'"
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
						/>
					</svg>
				</div>
			</div>
		</div>
		<div
				v-if="list.length"
				:class="$style.custom_table__rows"
		>
			<div
					v-for="(item, i) in list"
					:key="i"
					:class="$style.custom_table__rows_row"
					@click="$emit('input', item)"
			>
				<div
						v-for="({value, width, fontSize}, i) in columns"
						:key="i"
						:style="{
							width: width ? width + '%' : 5 + 'rem',
							fontSize: fontSize ? fontSize + 'rem' : 0.8 + 'rem'
						}"
						:title="value(item, true)"
						v-html="value(item)"
				/>
			</div>
		</div>
		<div
				v-else
				:class="$style.custom_table__empty"
		>
			Нет данных
		</div>
	</div>
</template>

<script>
  import orderBy from 'lodash/orderBy'
  export default {
    name: 'TableTemplate',
    props: {
      columns: {
        type: Array,
        default: () => []
      },
      items: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        list: [],
        sortConfig: {
          key: 'id',
          index: null,
          method: 'desc'
        }
      }
    },
    watch: {
      items () {
        this.sortItems(this.sortConfig)
      }
    },
    methods: {
      sortItems({key, method, index = null}) {
        this.sortConfig = {key, method, index}
        this.list = orderBy(this.items, key, method)
      }
    }
  }
</script>

<style lang="scss" module>
	@import '@/assets/styles/colors.scss';
	.custom_table {
		display: flex;
		flex-direction: column;
		width: max-content;

		&__columns {
			display: flex;

			&_column {
				padding: 0.5rem;
				display: flex;
				font-weight: 700;
				min-width: 5rem!important;
				align-items: center;
				justify-content: center;
				border: 1px solid #bebebe;
				border-right: none;
				position: relative;

				&:last-child {
					border-right: 1px solid #bebebe !important;
				}

				&_sort {
					position: absolute;
					right: 0.8rem;
					svg {
						position: absolute;
						cursor: pointer;
						height: 0.4rem;
						&:first-child {
							top: 0.4rem;
						}
						&:last-child {
							bottom: 0.4rem;
							transform: rotate(180deg);
						}
					}
					&_active {
						border: 1px solid red;
					}
				}
			}
		}

		&__rows {
			display: flex;
			flex-direction: column;

			&_row {
				display: flex;
				cursor: pointer;
				&:hover {
					background: #e7e4e4;
				}
				div {
					padding: 0.5rem;
					min-height: 1rem;
					display: flex;
					justify-content: center;
					width: 100%;
					min-width: 5rem!important;
					border: 1px solid #bebebe;
					border-right: none;
					border-top: none;
					overflow: hidden;

					&:last-child {
						border-right: 1px solid #bebebe !important;
					}
					svg {
						max-height: 1rem;
					}
				}
			}
		}

		&__empty {
			padding: 0.5rem;
			font-size: 0.75rem;
			border: 1px solid #bebebe;
			border-top: none;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
</style>
