var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.columns.length)?_c('div',{class:_vm.$style.custom_table},[_c('div',{class:_vm.$style.custom_table__columns},_vm._l((_vm.columns),function({column, width, fontSize, sort},i){return _c('div',{key:i,class:_vm.$style.custom_table__columns_column,style:({
					width: width ? width + '%' : 5 + 'rem',
					fontSize: fontSize ? fontSize + 'rem' : 1 + 'rem'
				})},[_vm._v(" "+_vm._s(column)+" "),(sort)?_c('div',{class:_vm.$style.custom_table__columns_column_sort},[_c('svg',{attrs:{"width":"9","height":"7","viewBox":"0 0 9 7","fill":"none","xmlns":"http://www.w3.org/2000/svg"},on:{"click":function($event){return _vm.sortItems({
							key: sort,
							method: 'asc',
							index: i
						})}}},[_c('path',{attrs:{"d":"M1.50281 1L4.7191 6L7.93539 1","stroke":_vm.sortConfig.key === sort && _vm.sortConfig.method === 'asc' ? 'red' : 'black',"stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('svg',{attrs:{"width":"9","height":"7","viewBox":"0 0 9 7","fill":"none","xmlns":"http://www.w3.org/2000/svg"},on:{"click":function($event){return _vm.sortItems({
							key: sort,
							method: 'desc',
							index: i
						})}}},[_c('path',{attrs:{"d":"M1.50281 1L4.7191 6L7.93539 1","stroke":_vm.sortConfig.key === sort && _vm.sortConfig.method === 'desc' ? 'red' : 'black',"stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])]):_vm._e()])}),0),(_vm.list.length)?_c('div',{class:_vm.$style.custom_table__rows},_vm._l((_vm.list),function(item,i){return _c('div',{key:i,class:_vm.$style.custom_table__rows_row,on:{"click":function($event){return _vm.$emit('input', item)}}},_vm._l((_vm.columns),function({value, width, fontSize},i){return _c('div',{key:i,style:({
						width: width ? width + '%' : 5 + 'rem',
						fontSize: fontSize ? fontSize + 'rem' : 0.8 + 'rem'
					}),attrs:{"title":value(item, true)},domProps:{"innerHTML":_vm._s(value(item))}})}),0)}),0):_c('div',{class:_vm.$style.custom_table__empty},[_vm._v(" Нет данных ")])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }